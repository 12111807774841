@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.TextStyles-H1 {
  font-size: 1.5rem;
  font-weight: 600;
}

.TextStyles-H2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.TextStyles-H3 {
  font-size: 0.9rem;
  font-weight: 500;
}

.TextStyles-H4 {
  font-size: 3rem;
  font-weight: 500;
}

.TextStyles-S1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.TextStyles-S2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.TextStyles-S3 {
  font-size: 0.9rem;
  font-weight: 500;
}

.TextStyles-B1 {
  font-size: 1.1rem;
}

.TextStyles-B2 {
  font-size: 0.9rem;
}

.TextStyles-B3 {
  font-size: 0.8rem;
}

.supplier-image-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.image-container {
  width: 100px;
  border-radius: 50px;
  object-fit: cover;
}
.product-image-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-banner-container {
  width: 100px;
  object-fit: cover;
}

a {
  color: white;
}

.supplier-description {
  padding: "2%"
}

.supplier-text {
  color: #9b51e0;
}
.blue-text {
  color: #2f80ed;
}
.MuiPickersDay-daySelected {
  background-color: #50c0ab !important;
}

.MuiTypography-root {
  font-family: "Montserrat" !important;
}

fieldset {
  border: none !important;
}

.MuiMenuItem-root {
  border: 1px solid #e0e0e0 !important;
  font-family: "Montserrat" !important;
  font-size: 0.9rem;
}

.modal-product-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scrollDiv::-webkit-scrollbar {
  display: block;
  width: 10px;
  background-color: #f5f5f5;
  }
  
.scrollDiv::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.modal-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 -10px 30px -20px #000;
  -moz-box-shadow: 0 -10px 30px -20px #000;
  box-shadow: 0 -10px 30px -20px #000;
}

.order-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.select-grey-placeholder {
  color: #828282 !important;
}

.select-black-placeholder {
  color: #000;
}

.processing-screen-body {
  background: #121214;
}